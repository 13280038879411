<template>
  <div id="lom-list">
    <v-container class="py-0">
      <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
        :fixed="true"
        v-if="SpeedDialFlag"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-account-circle </v-icon>
          </v-btn>
        </template>
        <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
        <v-btn fab dark small color="indigo" @click.prevent="addRecord">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
      </v-speed-dial>
    </v-container>
    <v-dialog v-model="editRecordPrompt" persistent max-width="75%">
      <lom-president-assign
        :pageDescription="editPageDescription"
        :editRecordPrompt="editRecordPrompt"
        @hideEditRecordPrompt="hideEditRecordPrompt"
        :recordIndex="recordToEdit"
        :record="record"
        v-if="editRecordPrompt"
      ></lom-president-assign>
    </v-dialog>
    <v-dialog v-model="previewRecordPrompt" persistent max-width="600px">
      <lom-documnets
        pageDescription="LOM Documents"
        :previewRecordPrompt="previewRecordPrompt"
        @hidePreviewRecordPrompt="hidePreviewRecordPrompt"
        :recordData="selectedData"
        v-if="previewRecordPrompt"
      ></lom-documnets>
    </v-dialog>
    <v-dialog eager v-model="LomDataPreviewFlag" v-if="LomDataPreviewFlag">
      <lom-information
        :PageTitle="LomDataPageTitle"
        :recordData="LomDataParams"
        @hideDialog="hideLomDataPreview"
        v-if="LomDataPreviewFlag"
      ></lom-information>
    </v-dialog>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }} - {{ YearName }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>
          {{ PageDescription }}
        </h4>
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form
            ref="form2"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="searchForm"
          >
            <v-row wrap>
              <!-- <v-col cols="12" md="3">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="JciYearCodeOptions"
                  :rules="JciYearCodeRules"
                  :loading="JciYearCodeOptionsLoading"
                  v-model="JciYearCode"
                  label="Year"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12" md="3">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  :loading="ZoneCodeOptionsLoading"
                  v-model="ZoneCode"
                  label="Zone"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="rows.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ rows.length }} loms found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <!-- <v-btn v-if="DeleteFlag == 1" color="error" class="mr-4" @click="deleteRecords">
                  Delete
                </v-btn> -->
              <p></p>
              <json-excel
                v-if="DownloadFlag == 1"
                :data="rows"
                :fields="excel_fields"
                worksheet="My Worksheet"
                :name="ExcelFileName"
              >
                <v-btn color="info" class="mr-4"> Download </v-btn>
              </json-excel>
            </v-col>
            <p></p>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :headers="tableColumns1"
                :items="rows"
                :items-per-page="50"
                :search="search"
                item-key="MemberId"
                :single-select="false"
                :show-select="false"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50],
                }"
              >
                <template v-slot:[`item.ActiveStatusTxt`]="{ item }">
                  <v-chip
                    :color="item.ActiveStatusColor"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:[`item.ProfilePic`]="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom v-if="item.TotalDocuments > 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="red"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="editAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-shield-account
                      </v-icon>
                    </template>
                    <span> Assign President </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="warning"
                        @click="showLomDataPreview(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-database
                      </v-icon>
                    </template>
                    <span> LOM Data </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import companyConfig from "@/company_config.json";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import LomPresidentAssign from "@/view/pages/erp/lom-president/LomPresidentAssign.vue";
import LomDocumnets from "@/view/pages/erp/lom/LomDocumnets.vue";

export default {
  mixins: [common],
  components: {
    LomPresidentAssign,
    LomDocumnets,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      SpeedDialFlag: false,
      rows: [],
      tableColumns1: [],
      selected: [],
      search: "",

      DownloadFlag: 1,
      ExcelFileName: "LOM_President_list.xls",
      excel_fields: {
        "Lom Id": "LomId",
        "Zone Id": "ZoneId",
        "Lom Name": "LomName",
        "President Name": "LomPresidentFullName",
        "LOM NAME": "LomName",
        "Mobile No": "LomPresidentMobileNo",
        "Email Id": "LomPresidentEmailid",
      },

      recordToEdit: "",
      record: {},
      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
      editPageDescription: "Assign LOM President for JCI India",

      LomDataPageTitle: "",
      LomDataPreviewFlag: false,
      LomDataParams: {},

      selectedData: {},

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      zone: {},
      lom: {},

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    JciYearCode: function () {
      console.log("watch JciYearCode");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = true;
      this.getZoneCodeOptions("ZoneCode", "ZoneCodeOptions");
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = false;
    },
    FromLomCodeOptions: function () {
      console.log("watch FromLomCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.FromLomCodeOptionsLoading = false;
    },
    ToZoneCodeOptions: function () {
      console.log("watch ToZoneCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ToZoneCodeOptionsLoading = false;
    },
    ToZoneCode: function () {
      console.log("watch ToZoneCode");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ToLomCodeOptionsLoading = true;
      this.getLomCodeOptions("ToLomCode", "ToLomCodeOptions", this.ToZoneCode);
    },
    ToLomCodeOptions: function () {
      console.log("watch ToLomCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ToLomCodeOptionsLoading = false;
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "lom_president",
        Action: "list_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.ZoneCodeOptionsLoading = true;
      this.getZoneCodeOptions("ZoneCode", "ZoneCodeOptions");
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/lom/create";
      var params = {};
      this.pageRedirect(params, url);
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      this.searchForm();
    },
    showLomDataPreview(tr) {
      console.log("showLomDataPreview called");
      console.log({ tr });
      this.LomDataPageTitle = tr.LomName + " information for the current year";
      this.LomDataPreviewFlag = true;
      this.LomDataParams = {
        UserInterface: 2,
        LomCode: tr.LomId,
      };
    },
    hideLomDataPreview() {
      console.log("hideLomDataPreview called");
      this.LomDataPreviewFlag = false;
      this.LomDataParams = {};
    },
    hideEditRecordPrompt(flag) {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      console.log(flag);
      if (flag) {
        this.searchForm();
      }
    },
    editAlert(tr) {
      console.log("editAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var LomId = tr.LomId;
        console.log("LomId=" + LomId);
        this.record = tr;
        this.recordToEdit = LomId;
        this.editPageDescription = " LOM President for " + tr.LomName;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to modifiy";
        this.toast("error", message);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    getZoneCodeOptions(source, destination) {
      console.log("getZoneCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      var CurrentYearId = this.JciYearCode;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", CurrentYearId=" +
          CurrentYearId
      );
      if (source != "" && destination != "" && CurrentYearId != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: CurrentYearId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions(source, destination, ZoneCode) {
      console.log("getLomCodeOptions called");
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", ZoneCode=" +
          ZoneCode
      );
      var CurrentYearId = this.CurrentYearId;
      if (ZoneCode != "" && CurrentYearId != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: CurrentYearId,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Zone should not be empty";
        this.toast("error", message);
      }
    },
    getYearCodeOptions(source, destination) {
      console.log("getYearCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // var CurrentYearId = this.CurrentYearId;
      var CurrentYearId = this.YearCode;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", CurrentYearId=" +
          CurrentYearId
      );
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/jci-year/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form2.validate();
      var SearchId = this.SearchId;
      var UserInterface = SearchId == 1 ? 1 : 3;
      console.log(
        "validate1=" +
          validate1 +
          ", SearchId=" +
          SearchId +
          ", UserInterface=" +
          UserInterface
      );

      if (validate1) {
        this.SearchFlag = true;
        this.rows = [];
        this.zone = {};
        this.lom = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-lom/lom-lists";
        var upload = {
          UserInterface: 2,
          YearCode: this.JciYearCode,
          ZoneCode: this.ZoneCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            table = response.data.TableContent;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.tableColumns1 = table.TableHeader;
            thisIns.rows = table.TableData;

            if (flag == 1) {
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#lom-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
}
</style>